import React from "react";

const About = () => {
  return (
    <div name="about" className="w-full h-screen bg-[#0a192f] text-gray-300">
      <div className="flex flex-col justify-center items-center w-full h-full">
        <div className="max-w-[1000px] w-full grid grid-cols-2 gap-8">
          <div className="sm:text-right pb-8 pl-4">
            <p className="text-4xl font-bold inline border-b-4 border-pink-600">
              About
            </p>
          </div>
          <div></div>
        </div>
        <div className="max-w-[1000px] w-full grid sm:grid-cols-2 gap-8 px-4">
          <div className="sm:text-right text-4xl font-bold">
            <p>
              Hi. I'm Youssef Ashour, nice to meet you. Please take a look
              around.
            </p>
          </div>
          <div>
            <p>
              As a web developer, I love building websites that look great and
              work smoothly. I'm all about combining design and coding to create
              awesome online experiences. Whether it's making sites that look
              good on any device or adding cool features, I'm here to make your
              web goals a reality. Let's work together to bring your ideas to
              life online!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
